import React from "react";

import { PieChart } from "@mui/x-charts/PieChart";

const colorPalette = [
  "#6a8490",
  "#7a95a0",
  "#8aa5b0",
  "#9ab6c0",
  "#aab7d0",
  "#babcdf",
  "#cbcee0",
  "#dbe0e1",
  "#ebf1f1",
  "#fef4f4",
  "#e0e7e9",
  "#c4c9ca",
];

const CasesPieChart = ({ data = [] }) => {
  const pieData = data?.map((job, index) => ({
    id: index,
    value: job?.count,
    label: job?.matter_type__name || "Unknown",
  }));

  return (
    <PieChart
      series={[
        {
          data: pieData,
        },
      ]}
      width={1120}
      height={350}
      colors={colorPalette}
    />
  );
};

export default CasesPieChart;
