import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  All COURTS SESSION
export const getCourtsSessionService = createAsyncThunk(
  "courts/getCourtsSession",
  async ({ id, page, session_id, search_value }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.courtsSessionURL}/${id}/?page=${page}&session_id=${session_id}`,
        { params: { search_value: search_value } }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>   COURT SESSION
export const getCourtSessionService = createAsyncThunk(
  "courts/getCourtSessionService",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.court_sessions}/${id}/show`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE  SESSION
export const createCourtsSessionServices = createAsyncThunk(
  "courts/createCourtsSession",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  UPDATE DELEGTION NOTE
export const updateDelegtionNoteServices = createAsyncThunk(
  "courts/updateDelegtionNote",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.updateDelegtionNoteURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER COURTS SERVICE
export const filterCourtsService = createAsyncThunk(
  "courts/filterCourtsService",
  async ({ data, page }, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.filterCourtsSessionURL}?page=${page || 1}`,
        data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Experts SERVICE
export const filterExpertsService = createAsyncThunk(
  "courts/filterExpertsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Administrative SERVICE
export const filterAdministrativeService = createAsyncThunk(
  "courts/filterAdministrativeService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  FILTER Excute SERVICE
export const filterExcuteService = createAsyncThunk(
  "courts/filterExcuteService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.filterCourtsSessionURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET ALL PROSEDUERS LIST
export const getProseduersService = createAsyncThunk(
  "courts/getProseduersService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(
        URLS.list_procedures_with_subprocedures,
        { params: { ...params } }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET ALL PROSEDUERS LIST
export const getAutoCompleteFieldService = createAsyncThunk(
  "courts/getAutoCompleteFieldService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.procedureStatementsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET AFFILATED AUTO COMPLETE LIST
export const getAffiliatedAutoCompleteFieldService = createAsyncThunk(
  "courts/getAffiliatedAutoCompleteFieldService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.affiliatedSectionURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
