import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";

import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";

import StatisticCard from "./StatisticCard";

import PeopleIcon from "@mui/icons-material/People";

import BusinessIcon from "@mui/icons-material/Business";

import CaseIcon from "@mui/icons-material/Work";

import CasesPieChart from "./CasesPieChart";

import RevenuesExports from "./RevenuesExports";

import JobsPieChart from "./EmployeesPieChart";

import CasesTypePieChart from "./CasesTypePieChart";

import CasesSessionTypePieChart from "./CasesSessionTypePieChart";

import { getStatisticService } from "../../../redux/services/statistics_service";

import { useDispatch, useSelector } from "react-redux";

import LoadingScreen from "../../../components/loading-screen/LoadingScreen";

//_____________________________________________________________

//---------- Styles -----------

const lawsuitTextStyle = {
  fontWeight: 700,
  fontSize: "24px",
  lineHeight: "28px",
  color: "#6A8490",
  mb: "10px",
};

//______________________________________________________________

const Statistics = () => {
  const dispatch = useDispatch();
  const {
    statisticList,
    isLoadingStatistic,
    matter_counts,
    employee_counts_List,
  } = useSelector((state) => state.Statistic);

  const data = [
    {
      title: "الموظفين",
      number: statisticList?.employee_count,
      IconComponent: PeopleIcon,
      type: "EMPLOYEES",
    },
    {
      title: "القضايا",
      number: statisticList?.matter_management_count,
      IconComponent: BusinessIcon,
      type: "CASES",
    },
    {
      title: "الموكلين",
      number: statisticList?.client_count,
      IconComponent: CaseIcon,
      type: "CLIENTS",
    },
  ];

  useEffect(() => {
    dispatch(getStatisticService());
  }, [dispatch]);

  return isLoadingStatistic ? (
    <LoadingScreen />
  ) : (
    <Box>
      <Grid container spacing={2} my={3} justifyContent="center">
        {data?.map((item, index) => (
          <Grid
            item
            xs={12}
            sm={4}
            key={index}
            display="flex"
            justifyContent="center"
          >
            <StatisticCard
              title={item.title}
              number={item.number}
              IconComponent={item.IconComponent}
              type={item.type}
            />
          </Grid>
        ))}
      </Grid>
      <Divider />
      <Grid container my={3} display="flex" justifyContent="center">
        <Grid item xs={12}>
          <RevenuesExports />
        </Grid>
      </Grid>
      <Divider />
      <Grid container spacing={1} my={3}>
        <Grid item xs={12} sm={12}>
          <Typography sx={{ ...lawsuitTextStyle }}>
            القضايا حسب النوع
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {" "}
            <CasesPieChart data={matter_counts} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography sx={{ ...lawsuitTextStyle }}>
            القضايا حسب الإنجاز
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <CasesTypePieChart
              completed_matter_count={statisticList?.completed_matter_count}
              not_completed_matter_count={
                statisticList?.not_completed_matter_count
              }
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} my={3} display="flex" justifyContent="center">
        <Grid item xs={12} sm={6}>
          <Typography sx={{ ...lawsuitTextStyle }}>
            الموظفين حسب المنصب
          </Typography>
          <JobsPieChart data={employee_counts_List} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography sx={{ ...lawsuitTextStyle }}>
            القضايا حسب نوع الجلسة
          </Typography>
          <CasesSessionTypePieChart
            data={statisticList?.court_session_counts}
          />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export default Statistics;
